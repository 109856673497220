import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import YouTube from "react-youtube";

import "./VideoModal.scss";

export default function VideoModal({ youtubeUrl, onHide, ...rest }) {
  const [videoPlayer, setVideoPlayer] = useState();

  function handleHide(e) {
    if (videoPlayer) videoPlayer.pauseVideo();
    if (onHide) onHide(e);
  }
  return (
    <Modal
      centered
      onHide={handleHide}
      aria-label="Modal with video"
      {...rest}
      className="VideoModal"
    >
      <YouTube
        videoId={youtubeUrl}
        opts={{ playerVars: { autoplay: true } }}
        onReady={(e) => setVideoPlayer(e.target)}
        className="VideoModal__video"
        containerClassName="VideoModal__video-container"
      />
    </Modal>
  );
}
